import * as React from "react"

const ImpressumPage = () => (
  <>
    <div id="impressum" className="modal">
      <div className="modal-background"></div>
      <div className="modal-card">
        {/* <header className="modal-card-head">
          <p className="modal-card-title">Impressum | Datenschutz</p>
          <button className="delete" aria-label="close"></button>
        </header> */}
        <section className="modal-card-body">
          <button className="delete" aria-label="close"></button>
          <div class="content">
            <h2>Impressum</h2>
            <p>
              Curabitur accumsan turpis pharetra{" "}
              <strong>augue tincidunt</strong> blandit. Quisque condimentum
              maximus mi, sit amet commodo arcu rutrum id. Proin pretium urna
              vel cursus venenatis. Suspendisse potenti. Etiam mattis sem
              rhoncus lacus dapibus facilisis. Donec at dignissim dui. Ut et
              neque nisl.
            </p>
            <ul>
              <li>
                In fermentum leo eu lectus mollis, quis dictum mi aliquet.
              </li>
              <li>
                Morbi eu nulla lobortis, lobortis est in, fringilla felis.
              </li>
              <li>
                Aliquam nec felis in sapien venenatis viverra fermentum nec
                lectus.
              </li>
              <li>Ut non enim metus.</li>
            </ul>
            <h3>Third level</h3>
            <p>
              Quisque ante lacus, malesuada ac auctor vitae, congue{" "}
              <a href="#">non ante</a>. Phasellus lacus ex, semper ac tortor
              nec, fringilla condimentum orci. Fusce eu rutrum tellus.
            </p>
            <ol>
              <li>Donec blandit a lorem id convallis.</li>
              <li>Cras gravida arcu at diam gravida gravida.</li>
              <li>Integer in volutpat libero.</li>
              <li>Donec a diam tellus.</li>
              <li>Aenean nec tortor orci.</li>
              <li>
                Quisque aliquam cursus urna, non bibendum massa viverra eget.
              </li>
              <li>Vivamus maximus ultricies pulvinar.</li>
            </ol>
            <p>
              Sed sagittis enim ac tortor maximus rutrum. Nulla facilisi. Donec
              mattis vulputate risus in luctus. Maecenas vestibulum interdum
              commodo.
            </p>
            <p>
              Suspendisse egestas sapien non felis placerat elementum. Morbi
              tortor nisl, suscipit sed mi sit amet, mollis malesuada nulla.
              Nulla facilisi. Nullam ac erat ante.
            </p>
          </div>
        </section>
      </div>
    </div>
  </>
)

export default ImpressumPage
